import { Result } from 'shared-types'

import { PageProps } from '~/components/Page'
import { ApiError } from '../helpers/apiError'
import { GetPagePayload, ICMSClient } from '../types/Client'
import { APIUrl } from './apiUrls'
import { request } from './request'

export class CMSClient implements ICMSClient {
  getPage = async ({
    session,
    queryString,
    queryClient,
  }: GetPagePayload): Promise<
    Result<
      {
        data: {
          page: PageProps
          dehydratedState: {
            key: [string, string]
            data: unknown
          }[]
          gtmId: string
        }
      },
      ApiError
    >
  > => {
    const response = await request<{
      data: {
        page: PageProps
        dehydratedState: {
          key: [string, string]
          data: unknown
        }[]
        gtmId: string
      }
    }>(
      {
        url: `${APIUrl.GetPage}`,
        method: 'GET',
        query: queryString,
      },
      session
    )
    const pageResponse = response.getValueSafe()
    if (pageResponse?.data?.dehydratedState?.length) {
      await queryClient.prefetchQuery(
        pageResponse?.data?.dehydratedState?.[0].key,
        () => {
          return pageResponse?.data?.dehydratedState?.[0]?.data
        }
      )
    }
    return response
  }
}

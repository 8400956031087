import { GTMEvent, BeginCheckoutDTO } from '~/lib'

import { adaptProduct } from './adaptProduct'

export const adaptBeginCheckout = (items): BeginCheckoutDTO => {
  return {
    event: GTMEvent.BEGIN_CHECKOUT,
    ecommerce: {
      currency: items.cart?.[0].price.value.currencyCode,
      items: items?.cart?.map((product) => {
        return adaptProduct({
          product,
        })
      }),
    },
  }
}

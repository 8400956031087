import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ChangePasswordOptions,
  CustomerAddress,
  DeactivateAccount,
  UpdateCustomerRequest,
  SavePaymentMethodRequest,
  EditPaymentMethodRequest,
} from 'shared-types'

import { Client } from '~/customClients/client'
import { useSession } from '../useSession'

const errorMessage = 'not implemented'

/**
 * Manage Customer authorization and data.
 */
export function useCustomer() {
  const { session } = useSession()
  const queryClient = useQueryClient()

  const {
    data: customer,
    refetch: getCustomer,
    isError: isErrorCustomer,
    isLoading: isLoadingCustomer,
    isFetching: isFetchingCustomer,
    isRefetching: isRefetchingCustomer,
  } = useQuery(
    ['account', session],
    async () => {
      if (!session?.token?.isLoggedIn && !session?.token?.customerId) {
        return null
      }
      const response = await Client.account.getCustomer(session)
      return response.getValue()
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!session,
    }
  )

  /**
   * Sign in a Customer
   * @param {MyCustomerUpdateAction[]} actions
   */
  const { mutate: updateCustomer } = useMutation(
    async () => {
      throw new Error(errorMessage)
    },
    {
      onSuccess: () => {
        throw new Error(errorMessage)
      },
    }
  )

  /**
   * Sign in a Customer
   * @param {SignInMutationParams} params
   */
  const { mutate: signIn } = useMutation(
    () => {
      throw new Error(errorMessage)
    },
    {
      onSuccess: async () => {
        throw new Error(errorMessage)
      },
    }
  )

  /**
   * Sign up a Customer
   * @param {SignUpMutationParams} params
   */
  const { mutate: signUp } = useMutation(
    async () => {
      throw new Error(errorMessage)
    },
    {
      onSuccess: async () => {
        throw new Error(errorMessage)
      },
    }
  )

  /**
   * Sign out a Customer
   * @returns {void}
   */
  const { mutate: signOut } = useMutation(
    async (): Promise<void> => {
      throw new Error(errorMessage)
    },
    {
      onSuccess: async () => {
        throw new Error(errorMessage)
      },
    }
  )

  /**
   * Changes Customer's Password
   */
  const { mutate: changePassword } = useMutation(
    async (payload: ChangePasswordOptions) => {
      return Client.account.changePassword(payload, session)
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: ['account'] })
      },
    }
  )

  /**
   * Changes Customer's account information
   */

  const { mutateAsync: updateAccount, isLoading: isLoadingUpdateAccount } =
    useMutation(
      async (payload: CustomerAddress) => {
        const res = await Client.account.updateAccount(payload, session)
        return res.getValueSafe()
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['account'] })
        },
      }
    )

  /**
   * Add Customer's address
   */

  const { mutateAsync: addAddress, isLoading: isAddAddressLoading } =
    useMutation(
      async (payload: CustomerAddress) => {
        return Client.account.addAddress(payload, session)
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['account'] })
        },
      }
    )

  /**
   * Changes Customer's address
   */

  const { mutateAsync: updateAddress, isLoading: isUpdateAddressLoading } =
    useMutation(
      async (payload: CustomerAddress) => {
        return Client.account.updateAddress(payload, session)
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['account'] })
        },
      }
    )

  /**
   * Deletes Customer's address
   */

  const { mutateAsync: deleteAddress, isLoading: isRemoveAddressLoading } =
    useMutation(
      async (id: string) => {
        const res = await Client.account.deleteAddress(id, session)
        return res.getValueSafe()
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['account'] })
        },
      }
    )

  /**
   * Updates Communication's preferences
   */

  const { mutate: updateCustomerPreferences } = useMutation(
    async (payload: UpdateCustomerRequest) => {
      const res = await Client.account.updateCustomerPreferences(
        payload,
        session
      )
      return res.getValueSafe()
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['account', session], data)
        // queryClient.invalidateQueries({ queryKey: ['account'] })
      },
    }
  )

  /**
   * Adds Payment Methods
   */

  const addPaymentMethods = useMutation(
    async (payload: SavePaymentMethodRequest) => {
      const res = await Client.account.addPaymentMethods(payload, session)
      return res.getValueSafe()
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['account'] })
      },
    }
  )

  /**
   * Updates Payment Methods
   */

  const updatePaymentMethods = useMutation(
    async (payload: EditPaymentMethodRequest) => {
      const { refId, ...rest } = payload

      const res = await Client.account.updatePaymentMethods(
        refId,
        rest,
        session
      )
      return res.getValueSafe()
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['account'] })
      },
    }
  )

  /**
   * Deletes Payment Methods
   */

  const deletePaymentMethods = useMutation(
    async (id: string) => {
      const res = await Client.account.deletePaymentMethods(id, session)
      return res.getValueSafe()
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['account'] })
      },
    }
  )

  /**
   * Deactivate Account
   */
  const deactivateAccount = useMutation(
    async (payload: DeactivateAccount) => {
      return Client.account.deactivateAccount(payload, session)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['account'] })
      },
    }
  )

  const defaultCustomerAddress = customer?.customerAddresses?.find(
    (customerAddress) => {
      return customerAddress.isDefault
    }
  )

  const isLoadingCustomerChanges =
    isRefetchingCustomer ||
    isAddAddressLoading ||
    isUpdateAddressLoading ||
    isRemoveAddressLoading

  return {
    customer,
    getCustomer,
    isErrorCustomer,
    isLoadingCustomer,
    isFetchingCustomer,
    isRefetchingCustomer,
    updateCustomer,
    signIn,
    signUp,
    signOut,
    changePassword,
    updateAccount,
    addAddress,
    isAddAddressLoading,
    updateAddress,
    deleteAddress,
    updateCustomerPreferences,
    addPaymentMethods,
    updatePaymentMethods,
    deactivateAccount,
    isLoadingUpdateAccount,
    deletePaymentMethods,
    defaultCustomerAddress,
    isUpdateAddressLoading,
    isRemoveAddressLoading,
    isLoadingCustomerChanges,
  }
}

import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin/Dropin'
import RedirectElement from '@adyen/adyen-web/dist/types/components/Redirect/Redirect'
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError'
import { useQueryClient } from '@tanstack/react-query'
import retry from 'async-retry'
import { Session } from 'next-auth'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import {
  CheckoutDetails,
  CheckoutPaymentRequest,
  CustomerAddress,
  DeliveryType,
  OrderSummary,
  Result,
  UpdateContactDetailsRequest,
} from 'shared-types'

import { getDeliveryTypeName } from '~/helpers'
import { ApiError } from '~/helpers/apiError'
import { useCheckoutDataLayer } from '~/lib/gtm/hooks'
import { Client } from '../../customClients/client'
import {
  usePage,
  useSession,
  useToast,
  useUserPreferredStore,
} from '../../hooks'
import {
  CheckoutInstanceProps,
  CheckoutPaymentMethodProps,
  CheckoutProviderProps,
  CustomerDetailState,
} from './CheckoutProvider.types'

export const enum CHECKOUT_STEP {
  customerDetail = 1,
  storeAndPaymentMethod = 2,
  paymentDetail = 3,
}

export const defaultCheckoutContextValue = {
  checkoutStep: 1,
  paymentInstance: null,
  isAddedDiscountCode: false,
  loading: false,
  checkoutDetails: {} as CheckoutDetails,
  orderDetails: {} as OrderSummary,
  payformInstance: null,
  isCardValid: null,
  cardFormInstance: null,
}

export const CheckoutProviderContext = createContext<CheckoutProviderProps>(
  defaultCheckoutContextValue
)

type CheckoutContextProviderProps = {
  initialCheckoutStep?: number
}

export const CheckoutProvider = ({
  children,
  initialCheckoutStep,
}: PropsWithChildren<CheckoutContextProviderProps>) => {
  const router = useRouter()
  const params = useSearchParams()
  const { showNotification, closeNotification } = useToast()
  const page = usePage()
  const forterToken = page?.forterToken
  const [paymentInstance, setPaymentInstance] =
    useState<CheckoutInstanceProps>(null)
  const [error, setError] = useState<string | null>(null)
  const [adyenError, setAdyenError] = useState<string | null>(null)
  const [checkoutStep, setCheckoutStep] = useState<number>(
    initialCheckoutStep ?? CHECKOUT_STEP.customerDetail
  )
  const [isAddedDiscountCode, setIsAddedDiscountCode] = useState(false)
  const [selectedSavedAddress, setSelectedSavedAddress] =
    useState<CustomerAddress | null>(null)
  const [checkoutDetails, setCheckoutDetails] = useState<CheckoutDetails>()
  const [orderDetails, setOrderDetails] = useState<OrderSummary>()
  const [customerDetailState, setCustomerDetailState] =
    useState<CustomerDetailState>(CustomerDetailState.NewAddress)
  const [loading, setLoading] = useState(false)
  const [redirectLoading, setRedirectLoading] = useState(false)
  const [showPlaceOrderButton, setShowPlaceOrderButton] = useState(true)
  const [payformInstance, setPayformInstance] = useState<RedirectElement>(null)
  const [cardFormInstance, setCardFormInstance] = useState<DropinElement>(null)
  const [paymentType, setPaymentType] = useState(null)
  const [isCardValid, setIsCardValid] = useState(null)
  const [placeFound, setPlaceFound] = useState(false)
  const [showMethods, setShowMethods] = useState(false)
  const { dispatchStepOptionSelectEvent } = useCheckoutDataLayer()
  const [giftCardId, setGiftCardId] = useState('')
  const [selectedPayment, setSelectedPayment] =
    useState<CheckoutPaymentMethodProps>()
  const { session } = useSession()
  const queryClient = useQueryClient()
  const { store } = useUserPreferredStore()
  const isNewCardSaved = useRef(false)
  const [storesPurchaseOrderNumber, setStoresPurchaseOrderNumber] = useState('')

  const createOrder = useCallback(
    async (
      sessionData?: string,
      gatewayReference?: string,
      merchantReference?: string,
      transactionReference?: string
    ) => {
      if (!session || loading) {
        return
      }

      setLoading(true)
      setRedirectLoading(true)

      let response: Result<OrderSummary, ApiError>
      if (sessionData) {
        response = await Client.order.createOrder(
          {
            paymentSession: sessionData,
          },
          session
        )
      } else {
        response = await Client.order.createOrderWithLatitudePay(
          {
            gatewayReference,
            merchantReference,
            transactionReference,
          },
          session
        )
      }

      setLoading(false)

      const apiError = response.getErrorSafe()
      if (apiError) {
        const errorMsg = apiError.body?.message || apiError.message
        showNotification({
          id: 'create-order-latitude-failed',
          children: errorMsg,
          type: 'error',
          onClose: closeNotification,
        })
        setError(errorMsg)
        setRedirectLoading(false)
        return
      }

      const result = response.getValue()
      setOrderDetails(result)
      queryClient.removeQueries({
        queryKey: ['cart'],
      })
      queryClient.invalidateQueries({
        queryKey: ['account'],
      })

      cardFormInstance?.remove()
      payformInstance?.remove()
      router.replace(`/checkout/${result.orderNumber || ''}`)
    },
    [
      cardFormInstance,
      closeNotification,
      loading,
      payformInstance,
      queryClient,
      router,
      session,
      showNotification,
    ]
  )

  const placeOrder = useCallback(
    async ({
      reference,
      redirectResult,
    }: {
      reference: string
      redirectResult?: string
    }) => {
      return retry(
        async (bail) => {
          const ref = reference?.split(':')?.[0]
          const response = await Client.order.getOrder(
            { reference: ref, redirectResult },
            session
          )
          const apiError = response.getErrorSafe()

          if (apiError) {
            const errorMsg = apiError.body?.message || apiError.message
            if (apiError.status === Number('423')) {
              throw Error('423 Locked')
            }
            if (apiError.body?.statusCode === Number('403')) {
              return bail(Error(errorMsg))
            }
            showNotification({
              id: 'place-order-failed',
              children: errorMsg,
              type: 'error',
              onClose: closeNotification,
            })
            await Client.cart.unFreezeCart(session)
            throw Error(errorMsg)
          }
          return response.getValueSafe()
        },
        {
          retries: 5,
        }
      )
    },
    [closeNotification, session, showNotification]
  )

  const [isTransactionDeclined, setIsTransactionDeclined] = useState(false)
  const shouldRemountAsDeclined = useRef(false)
  const getAdyenConfig = useCallback(
    async (
      currentPaymentSession,
      reference: string,
      redirectResult?: string
    ) => {
      return {
        locale: 'en_AU',
        environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT ?? 'TEST',
        session: currentPaymentSession,
        clientKey: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY,
        showPayButton: false,

        onChange: (state) => {
          if (state?.data?.paymentMethod?.type) {
            setPaymentType(state.data.paymentMethod.type)
          }

          state?.isValid ? setIsCardValid(state.isValid) : setIsCardValid(null)
        },
        onPaymentCompleted: async ({
          resultCode,
        }: {
          resultCode: string
          sessionData: string
        }) => {
          setRedirectLoading(true)
          try {
            const shouldPlaceOrder = ['Authorised', 'Received'].includes(
              resultCode
            )
            if (!shouldPlaceOrder) {
              throw Error(resultCode)
            }

            const orderSummary = (await placeOrder({
              reference,
              redirectResult,
            })) as OrderSummary

            queryClient.removeQueries({
              queryKey: ['cart'],
            })
            queryClient.invalidateQueries({
              queryKey: ['account'],
            })

            await router.replace(`/checkout/${orderSummary?.orderNumber || ''}`)
          } catch (error) {
            await Client.cart.unFreezeCart(session)
            showNotification({
              id: 'adyen-checkout-failed',
              children: error.message,
              type: 'error',
              onClose: closeNotification,
            })

            setShowPlaceOrderButton(true)
            setAdyenError(resultCode)
            setError(error.message)

            if (error.message !== 'Transaction declined') {
              router.replace('/checkout', null, { shallow: true })
              paymentInstance?.update()
              setRedirectLoading(false)
              setLoading(false)
              return
            }
            setIsTransactionDeclined(true)
            setLoading(false)
            shouldRemountAsDeclined.current = true
          }
        },
        onError: async (error: AdyenCheckoutError) => {
          await Client.cart.unFreezeCart(session)
          paymentInstance?.update()
          setSelectedPayment(null)
          setShowPlaceOrderButton(true)
          setLoading(false)
          setRedirectLoading(false)
          if (error?.name === 'CANCEL') {
            return
          }
          showNotification({
            id: 'adyen-checkout-error',
            children: error.message,
            type: 'error',
            onClose: closeNotification,
          })
          setAdyenError(error.message)
          setError(error.message)
          router.replace('/checkout', null, { shallow: true })
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            billingAddressRequired: false,
          },
          paypal: {
            showPayButton: true,
          },
        },
      }
    },
    [
      closeNotification,
      paymentInstance,
      placeOrder,
      queryClient,
      router,
      session,
      showNotification,
    ]
  )

  const useEffectWasCalled = useRef(false)
  useEffect(() => {
    if (!session || !params || useEffectWasCalled.current) {
      return
    }

    useEffectWasCalled.current = true

    const sessionId = params?.get('sessionId')
    const transactionReference = params?.get('transactionReference')
    const reference = params?.get('reference')
    const redirectResult = params?.get('redirectResult')
    const gatewayReference = params?.get('gatewayReference')
    const merchantReference = params?.get('merchantReference')

    if (gatewayReference && merchantReference && transactionReference) {
      setRedirectLoading(true)
      createOrder('', gatewayReference, merchantReference, transactionReference)
      return
    }

    if (sessionId || transactionReference) {
      setRedirectLoading(true)
      setCheckoutStep(CHECKOUT_STEP.paymentDetail)

      const handlePaymentSession = async () => {
        const config = await getAdyenConfig(
          { id: sessionId },
          reference,
          redirectResult
        )

        const AdyenCheckout = (await import('@adyen/adyen-web')).default
        const checkout = await AdyenCheckout(config)

        checkout.submitDetails({ details: { redirectResult } })
        setPaymentInstance(checkout)
      }
      handlePaymentSession()
    }
  }, [
    createOrder,
    getAdyenConfig,
    params,
    placeOrder,
    queryClient,
    router,
    session,
  ])

  const continueToDelivery = useCallback(
    async (formdata: UpdateContactDetailsRequest) => {
      if (!session) {
        return false
      }

      setLoading(true)
      const data = await Client.checkout.updateContactDetails(session, formdata)
      setLoading(false)

      if (data.getErrorSafe()) {
        setLoading(false)
        setError(data.getErrorSafe().body?.message)
        return false
      }

      setCheckoutDetails(data.getValueSafe())
      setCheckoutStep(CHECKOUT_STEP.storeAndPaymentMethod)
      return true
    },
    [session, setLoading, setCheckoutDetails, setCheckoutStep]
  )

  const applyCredit = useCallback(async () => {
    if (!session) {
      return false
    }

    setLoading(true)
    const data = await Client.checkout.applyCredit(session)
    setLoading(false)

    if (data.getErrorSafe()) {
      return false
    }

    const orderNumber = data.getValueSafe().orderNumber
    if (orderNumber) {
      router.replace(`/checkout/${orderNumber}`)
    }
    return true
  }, [session, router])

  const redeemGiftCard = useCallback(async () => {
    if (!session) {
      return false
    }

    setLoading(true)
    const data = await Client.checkout.redeemGiftCard({ giftCardId }, session)

    const apiError = data.getErrorSafe()
    if (apiError) {
      const errorMsg = apiError.body?.message || apiError.message
      showNotification({
        id: 'gift-card-failed',
        children: errorMsg,
        type: 'error',
        onClose: closeNotification,
      })
      setLoading(false)
      return false
    }

    const orderNumber = data.getValueSafe().orderNumber
    if (orderNumber) {
      router.replace(`/checkout/${orderNumber}`)
    }
    return true
  }, [closeNotification, giftCardId, router, session, showNotification])

  const getPaymentSession = useCallback(
    async (payload: CheckoutPaymentRequest) => {
      if (!session) {
        return
      }

      setLoading(true)
      const data = await Client.checkout.getPaymentSession(session, payload)
      const paymentSession = JSON.parse(data.getValue().paymentSession)

      if (data && paymentSession) {
        const config = await getAdyenConfig(
          paymentSession,
          paymentSession?.reference
        )

        const AdyenCheckout = (await import('@adyen/adyen-web')).default
        const checkout = await AdyenCheckout(config)
        setPaymentInstance(checkout)
        setCheckoutStep(CHECKOUT_STEP.paymentDetail)
        setCheckoutDetails(data.getValue())
        setLoading(false)
      }
    },
    [session, getAdyenConfig]
  )

  const setBillingAddress = useCallback(
    async (formdata: UpdateContactDetailsRequest) => {
      if (!session) {
        return false
      }

      setLoading(true)
      const data = await Client.checkout.updateBillingAddress(session, formdata)
      setLoading(false)

      if (data.getErrorSafe()) {
        setLoading(false)
        setError(data.getErrorSafe().body?.message)
        return false
      }

      setCheckoutDetails(data.getValue())
      return true
    },
    [session, setLoading]
  )

  const updateShippingMethod = useCallback(
    async (deliveryCode: string) => {
      setLoading(true)
      const details = await Client.checkout.updateShippingMethod(
        deliveryCode,
        session
      )
      if (details.getErrorSafe()) {
        setLoading(false)
        return
      }
      setLoading(false)
      setCheckoutDetails(details.getValueSafe())
    },
    [session]
  )

  const addInsurance = useCallback(async () => {
    setLoading(true)
    const details = await Client.checkout.addInsurance(session)
    if (details.getErrorSafe()) {
      setLoading(false)
      return
    }
    setLoading(false)
    setCheckoutDetails(details.getValueSafe())
  }, [session])

  const removeInsurance = useCallback(async () => {
    setLoading(true)
    const details = await Client.checkout.removeInsurance(session)
    if (details.getErrorSafe()) {
      setLoading(false)
      return
    }
    setLoading(false)
    setCheckoutDetails(details.getValueSafe())
  }, [session])

  const addCoupon = useCallback(
    async (count: number) => {
      setLoading(true)
      const details = await Client.checkout.addCoupon(count, session)
      if (details.getErrorSafe()) {
        setLoading(false)
        return
      }
      setLoading(false)
      setCheckoutDetails(details.getValueSafe())
    },
    [session]
  )

  const removeCoupon = useCallback(async () => {
    setLoading(true)
    const details = await Client.checkout.removeCoupon(session)
    if (details.getErrorSafe()) {
      setLoading(false)
      return
    }
    setLoading(false)
    setCheckoutDetails(details.getValueSafe())
  }, [session])

  const [checkoutPaymentStepId, setCheckoutPaymentStepId] = useState(1)
  const hasCardsDropInMounted = useRef(false)
  const continueToPayment = useCallback(
    async (payload: CheckoutPaymentRequest) => {
      hasCardsDropInMounted.current = false
      await getPaymentSession({ ...payload, forterCookie: forterToken })
      setCheckoutPaymentStepId((prev) => {
        return prev + 1
      })
    },
    [getPaymentSession, forterToken]
  )

  useEffect(() => {
    // Re-fetch payment session incase of a Failed Transaction
    if (isTransactionDeclined && shouldRemountAsDeclined.current) {
      cardFormInstance?.remove()
      payformInstance?.remove()
      hasCardsDropInMounted.current = false
      setPaymentInstance(null)
      setCardFormInstance(null)
      shouldRemountAsDeclined.current = false
      continueToPayment({
        deliveryCode: checkoutDetails?.selectedShippingMethod,
        storeKey: store?.storeKey,
        storesPurchaseOrderNumber,
      }).then(() => {
        setIsTransactionDeclined(false)
        setLoading(false)
        setRedirectLoading(false)
      })
    }
  }, [
    cardFormInstance,
    checkoutDetails?.selectedShippingMethod,
    continueToPayment,
    isTransactionDeclined,
    payformInstance,
    paymentInstance,
    store?.storeKey,
    storesPurchaseOrderNumber,
  ])

  const fetchCheckoutDetails = useCallback(async (): Promise<void> => {
    if (!session) {
      return
    }

    const response = await Client.checkout.getCheckoutDetails(session)
    if (response.getErrorSafe()) {
      router.replace('/')
      return
    }

    const result = response.getValueSafe()
    if (!result?.cartSummary?.lineItems?.length) {
      router.replace('/')
      return
    }
    setCheckoutDetails(result)
  }, [session, setCheckoutDetails, router])

  const handleCartChangeQuantity = useCallback(
    async (lineItemId: string, sku: string, quantity: number) => {
      const response = await Client.checkout.updateCart(
        [
          {
            lineItemId,
            quantity,
            sku,
          },
        ],
        session
      )
      if (response.getErrorSafe()) {
        // Show toast
        return
      }
      const value = response.getValue()

      queryClient.setQueryData(['cart', session], value.cartSummary)
      setCheckoutDetails(value)
    },
    [queryClient, session]
  )

  const addDiscountCode = useCallback(
    async (code: string, session: Session) => {
      setLoading(true)
      if (code) {
        setIsAddedDiscountCode(true)
        const details = await Client.checkout.addDiscountCode(code, session)

        const apiError = details.getErrorSafe()
        if (apiError) {
          const errorMsg = apiError.body?.message || apiError.message
          showNotification({
            id: 'discount-code-failed',
            children: errorMsg,
            type: 'error',
            onClose: closeNotification,
          })
          setLoading(false)
          setIsAddedDiscountCode(false)
          return
        }
        setCheckoutDetails(details.getValueSafe())
      }
      setLoading(false)
      setIsAddedDiscountCode(false)
    },
    [closeNotification, showNotification]
  )

  const removeDiscountCode = useCallback(async () => {
    setLoading(true)
    const details = await Client.checkout.removeDiscountCode(session)
    if (details.getErrorSafe()) {
      setLoading(false)
      return
    }
    setLoading(false)
    setIsAddedDiscountCode(false)
    setCheckoutDetails(details.getValueSafe())
  }, [session])

  const updateDeliveryType = useCallback(
    async (deliveryType: DeliveryType) => {
      if (!session || loading) {
        return
      }

      setLoading(true)
      const response = await Client.checkout.updateDeliveryType(
        deliveryType,
        session
      )
      if (!response.getErrorSafe()) {
        setCheckoutDetails(response.getValue())
        dispatchStepOptionSelectEvent(getDeliveryTypeName(deliveryType))
      }
      setLoading(false)
    },
    [dispatchStepOptionSelectEvent, session, loading]
  )

  const getLatitudeSession = useCallback(async () => {
    const response = await Client.checkout.getLatitudeUrl(session)
    if (response.getErrorSafe()) {
      showNotification({
        children: response.getErrorSafe().message,
        onClose: closeNotification,
      })
    }
    const data = response.getValueSafe()
    return data?.url
  }, [closeNotification, session, showNotification])

  const clearError = useCallback(() => {
    setError(null)
    setAdyenError(null)
  }, [setError])

  useEffect(() => {
    fetchCheckoutDetails()
  }, [fetchCheckoutDetails])

  useEffect(() => {
    if (selectedSavedAddress?.id) {
      setError('')
    }
  }, [selectedSavedAddress?.id])

  const result = useMemo(() => {
    return {
      loading,
      checkoutStep,
      isAddedDiscountCode,
      selectedSavedAddress,
      paymentInstance,
      error,
      adyenError,
      addDiscountCode,
      continueToDelivery,
      setCheckoutStep,
      getPaymentSession,
      checkoutDetails,
      continueToPayment,
      orderDetails,
      updateDeliveryType,
      customerDetailState,
      setCustomerDetailState,
      handleCartChangeQuantity,
      addInsurance,
      removeInsurance,
      payformInstance,
      setPayformInstance,
      setSelectedSavedAddress,
      setLoading,
      removeDiscountCode,
      clearError,
      paymentType,
      updateShippingMethod,
      applyCredit,
      setBillingAddress,
      getLatitudeSession,
      isCardValid,
      cardFormInstance,
      setCardFormInstance,
      setGiftCardId,
      giftCardId,
      redeemGiftCard,
      setIsCardValid,
      selectedPayment,
      setSelectedPayment,
      placeFound,
      setPlaceFound,
      showPlaceOrderButton,
      setShowPlaceOrderButton,
      redirectLoading,
      setRedirectLoading,
      setError,
      addCoupon,
      removeCoupon,
      isTransactionDeclined,
      checkoutPaymentStepId,
      setCheckoutPaymentStepId,
      hasCardsDropInMounted,
      setShowMethods,
      isNewCardSaved,
      storesPurchaseOrderNumber,
      setStoresPurchaseOrderNumber,
    }
  }, [
    loading,
    checkoutStep,
    isAddedDiscountCode,
    selectedSavedAddress,
    paymentInstance,
    error,
    adyenError,
    addDiscountCode,
    continueToDelivery,
    getPaymentSession,
    checkoutDetails,
    continueToPayment,
    orderDetails,
    updateDeliveryType,
    customerDetailState,
    handleCartChangeQuantity,
    addInsurance,
    removeInsurance,
    payformInstance,
    removeDiscountCode,
    clearError,
    paymentType,
    updateShippingMethod,
    applyCredit,
    setBillingAddress,
    getLatitudeSession,
    isCardValid,
    cardFormInstance,
    giftCardId,
    redeemGiftCard,
    selectedPayment,
    placeFound,
    showPlaceOrderButton,
    redirectLoading,
    addCoupon,
    removeCoupon,
    isTransactionDeclined,
    checkoutPaymentStepId,
    storesPurchaseOrderNumber,
    setStoresPurchaseOrderNumber,
  ])

  return (
    <CheckoutProviderContext.Provider value={result}>
      {children}
    </CheckoutProviderContext.Provider>
  )
}

import { Category, ProductList } from 'shared-types'
import {
  GTMEvent,
  ViewItemListDTO,
  ProductImpression,
} from '~/lib/gtm/declarations'
import { adaptViewItemImpression } from './adaptViewItemImpression'

export const adaptViewItemList = ({
  products,
  category,
}: {
  products: ProductList
  category: Category
}): ViewItemListDTO => {
  return {
    event: GTMEvent.VIEW_ITEM_LIST,
    ecommerce: {
      item_list_id: category.id ? category.id : category?.children?.[0]?.id,
      item_list_name: category.id
        ? category.name
        : category?.children?.[0]?.name,
      items: (function () {
        const productImpressions: Array<ProductImpression> = []
        products?.forEach((product, index) => {
          productImpressions.push(
            adaptViewItemImpression({ product, index, category })
          )
        })
        return productImpressions
      })(),
    },
  }
}

import { formatPriceForDataLayer } from '.'

export const formatLastCartItemForDataLayer = (item) => {
  if (item !== null && item !== undefined) {
    return {
      id: item?.variant?.sku,
      price: formatPriceForDataLayer(item?.price?.value?.centAmount),
      quantity: item.quantity,
    }
  }
  return []
}

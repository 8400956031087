export class CancellablePromise<T> implements Promise<T> {
  readonly [Symbol.toStringTag]!: string

  private _resolve: (value: T | PromiseLike<T>) => void

  private _reject: (message: unknown) => void

  private readonly _promise: Promise<T>

  private _isCancelled = false

  private _isRejected = false

  private _isResolved = false

  private _cancelHandler: (() => void)[] = []

  constructor(
    executor: (
      onResolve: (value: T | PromiseLike<T>) => void,
      onReject: (message?: unknown) => void,
      onCancel: (handler: () => void) => void
    ) => void
  ) {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject

      const onResolve = (value: T | PromiseLike<T>) => {
        if (this._isCancelled || this._isResolved || this._isRejected) {
          return
        }
        this._isResolved = true
        this._resolve(value)
      }

      const onReject = (message: unknown) => {
        if (this._isCancelled || this._isResolved || this._isRejected) {
          return
        }
        this._isRejected = true
        reject(message)
      }
      const onCancel = (handler) => {
        if (this._isCancelled || this._isResolved || this._isRejected) {
          return
        }
        this._cancelHandler.push(handler)
      }
      executor(onResolve, onReject, onCancel)
    })
  }

  public catch<TResult = never>(
    onrejected?: (reason: unknown) => TResult | PromiseLike<TResult>
  ): Promise<T | TResult> {
    return this._promise.catch(onrejected)
  }

  public finally(onfinally?: () => void): Promise<T> {
    return this._promise.finally(onfinally)
  }

  public then<TResult1 = T, TResult2 = never>(
    onfulfilled?: (value: T) => TResult1 | PromiseLike<TResult1>,
    onrejected?: (reason: unknown) => TResult2 | PromiseLike<TResult2>
  ): Promise<TResult1 | TResult2> {
    return this._promise.then(onfulfilled, onrejected)
  }

  public cancel = () => {
    if (this._isResolved || this._isRejected || this._isCancelled) {
      return
    }
    if (this._cancelHandler.length) {
      try {
        for (const handler of this._cancelHandler) {
          handler()
        }
      } catch (err) {
        return
      }
    }
    this._cancelHandler = []
    this._isCancelled = true
  }
}

import { useEffect, useRef } from 'react'
import { Cart } from 'shared-types'

import { GTM, GTMEvent } from '~/lib'

export const useCheckoutDataLayer = ({
  // GTM event that need to dispatch on checkout page load
  event,
  cart,
}: {
  event?:
    | GTMEvent.BEGIN_CHECKOUT
    | GTMEvent.ADD_SHIPPING_INFO
    | GTMEvent.ADD_PAYMENT_INFO
  cart?: Cart
} = {}) => {
  const dispatchCartEventWasCalled = useRef(false)
  const selectOption = (option: string) => {
    GTM.dispatch(GTMEvent.SELECT_CHECKOUT_OPTION, option)
  }
  useEffect(() => {
    if (!cart || !event || dispatchCartEventWasCalled.current) {
      return
    }

    dispatchCartEventWasCalled.current = true
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(event, { cart: cart?.lineItems })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  return {
    dispatchStepOptionSelectEvent: selectOption,
  }
}

import { LineItem } from './cart'
import { Order as SdkOrder, Money, OrderState, ReturnInfo, OrderSource } from './commercetools'
import { Image } from './image'

export interface Order extends Omit<SdkOrder, 'lineItems'> {
  lineItems: Array<LineItem>
  orderNumber: string
  value: number
  tax: number
  shipping: number
  currency: string
  coupon: string
  insurance: number
  paymentMethod: string
}

type CardPopupMenuOptions = {
  action: string
  label: string
  icon: string
}

export interface OrderSummary {
  id: string
  orderNumber?: string
  totalPrice: Money
  orderState: OrderState
  orderSource?: OrderSource
  orderSourceLabel?: string
  createdAt: string
  orderProcessedAt?: string
  shippedAt?: string
  predictedDeliveryAtRangeFrom?: string
  predictedDeliveryAtRangeTo?: string
  outForDeliveryAt?: string
  deliveredAt?: string
  lineItems?: Array<LineItem>
  title?: string
  description?: string
  orderedOn?: string
  returnInfo?: ReturnInfo[]
  earnRewards?: {
    // This won't be sent in from BE for signedInUsers
    image: Image
    title: string
    description: string
    action: {
      title: string
      href: string
    }
  }
  actions?: CardPopupMenuOptions[]
  deliveryType?: string
  status?: string
}

export interface CreateOrderRequest {
  paymentSession: string
}

export interface CreateOrderWithLatitudePayRequest {
  gatewayReference: string
  merchantReference: string
  transactionReference: string
}

export interface OrderListResponse {
  totalPages?: number
  pageNumber?: number
  totalOrders?: number
  limit?: number
  count?: number
  orders: OrderSummary[]
  workatoOrders: WorkatoOrderHistory
  fullFilledOrders?: number
  currentOrderQuantity?: number
}

export interface ProductItemsOrderReturn {
  lineItemId: string
  quantity: number
  comment?: string
  shipmentState?: string
}

export type GetOrderParams = {
  reference: string
  redirectResult?: string
}

export interface DiscountOnTotalPrice {
  discountOnTotalPrice: {
    discountedAmount: {
      type: string
      currencyCode: string
      centAmount: number
      fractionDigits: number
    }
    includedDiscounts: [
      {
        discount: {
          typeId: string
          id: string
        }
        discountedAmount: {
          type: string
          currencyCode: string
          centAmount: number
          fractionDigits: number
        }
      },
    ]
  }
}

export enum DeliveryStatus {
  Placed = 'order-placed',
  Processing = 'processing',
  InTransit = 'intransit',
  OutForDelivery = 'outfordelivery',
  Delivered = 'delivered',
  Returned = 'Returned',
  Advised = 'Advised',
  ReadyToCollect = 'readytocollect',
  Cancelled = 'cancelled',
}

export interface WorkatoOrderSummary {
  code: string
  createdts: string
  totalprice: number
  // eslint-disable-next-line camelcase
  customer_id: string
  // eslint-disable-next-line camelcase
  product_counts: number
  // eslint-disable-next-line camelcase
  order_status: string
  // eslint-disable-next-line camelcase
  order_source: OrderSource
  // eslint-disable-next-line camelcase
  order_source_label: string
}

export interface WorkatoOrderHistoryResponse {
  message: string
  totalPages: number
  totalCount: number
  ordersummarydata: WorkatoOrderSummary[]
}

export interface WorkatoOrderHistory {
  totalPages: number
  totalCount: number
  orderSummary: OrderSummary[]
}

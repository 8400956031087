import { GTMEvent } from '~/lib/gtm/GTM'
import { formatPriceForDataLayer } from '~/lib/gtm/helpers'

export const adaptAddToList = ({ product }) => {
  const productObj = (item) => {
    return {
      item_id: item?.sku,
      item_name: item?.name || item?.title,
      price: Number(formatPriceForDataLayer(item?.finalPrice?.centAmount)),
      item_brand: item?.brandName,
      item_category: item?.category1Name,
      item_category2: item?.category2Name,
      item_category3: item?.category3Name,
      item_category4: item?.category4Name,
      item_category5: item?.category5Name,
      quantity: 1,
      item_list_id: item?.listId,
      item_list_name: item?.listName,
      item_status_tags: item?.item_status_tags,
    }
  }
  return {
    event: GTMEvent.ADD_TO_LIST,
    ecommerce: {
      currency:
        product?.finalPrice?.currencyCode ||
        product?.[0]?.finalPrice?.currencyCode,
      items: Array.isArray(product)
        ? product.map((item) => {
            return productObj(item)
          })
        : [productObj(product)],
    },
  }
}

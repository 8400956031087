import { Session } from 'next-auth'
import { IArticlesClient } from '~/types/Client'
import { PaginationInfo } from '~/providers/PaginationProvider/PaginationProvider'
import { ArticleList } from '~/sections/MakerHubArticleListing/MakerHubArticleListing.types'
import { request } from './request'

export type GetArticleListResponse = PaginationInfo & {
  articles: ArticleList
}

export class ArticlesClient implements IArticlesClient {
  getArticleList = async (
    params,
    session: Session
  ): Promise<GetArticleListResponse> => {
    const response = await request<GetArticleListResponse>(
      {
        url: `/bff/products/list?${params}`,
        method: 'GET',
      },
      session
    )
    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }
}

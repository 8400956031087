/* eslint-disable camelcase */
import { GTMEvent, AddShippingInfoDTO } from '~/lib'

import { adaptProduct } from './adaptProduct'

export const adaptAddShippingInfo = ({
  items,
  shippingTier,
}): AddShippingInfoDTO => {
  return {
    event: GTMEvent.ADD_SHIPPING_INFO,
    ecommerce: {
      currency: items?.[0]?.price?.value?.currencyCode,
      shipping_tier: shippingTier,
      items:
        items?.map((product) => {
          return adaptProduct({
            product,
          })
        }) || [],
    },
  }
}

import { Session } from 'next-auth'
import { QuickSearchProductResponse, Result } from 'shared-types'
import { ApiError } from '~/helpers/apiError'
import { ISearchResultsClient } from '~/types/Client'
import { request } from './request'

export class SearchResultClient implements ISearchResultsClient {
  public getSearchResults = async (
    payload: string,
    session: Session
  ): Promise<Result<QuickSearchProductResponse, ApiError>> => {
    return request<QuickSearchProductResponse>(
      {
        url: `/bff/products/search?q=${encodeURIComponent(payload)}`,
        method: 'GET',
      },
      session
    )
  }
}

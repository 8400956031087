import { Session } from 'next-auth'
import { GetProductListResponse, ProductList, Result } from 'shared-types'
import { ApiError } from '~/helpers/apiError'
import { IProductsClient } from '~/types/Client'
import { request } from './request'

export class ProductsClient implements IProductsClient {
  getProductList = async (
    params,
    session: Session
  ): Promise<GetProductListResponse> => {
    const response = await request<GetProductListResponse>(
      {
        url: `/bff/products/list?${params}`,
        method: 'GET',
      },
      session
    )
    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }

  subscribeProduct = async (
    productCode: string,
    session: Session
  ): Promise<Result<string, ApiError>> => {
    return request<string>(
      {
        url: `/bff/products/subscribe`,
        method: 'POST',
        body: { productCode },
      },
      session
    )
  }

  getProductsByIds = async (
    productIds: string[],
    session: Session
  ): Promise<ProductList> => {
    const response = await request<ProductList>(
      {
        url: `/bff/products/listBySlugIds?productIds=${productIds?.join('|')}`,
        method: 'GET',
      },
      session
    )

    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }
}

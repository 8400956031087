import { formatPriceForDataLayer } from '~/lib/gtm/helpers'

export const adaptViewItemImpression = ({ product, index = 0, category }) => {
  return {
    item_id: product?.sku,
    item_name: product?.title,
    price: Number(
      formatPriceForDataLayer(
        product?.price?.value?.centAmount || product?.finalPrice?.centAmount
      )
    ),
    item_brand: product?.brandName,
    item_category: product?.category1Name,
    item_category2: product?.category2Name,
    item_category3: product?.category3Name,
    item_category4: product?.category4Name,
    item_category5: product?.category5Name,
    item_list_id: category.id ? category.id : category?.children?.[0]?.id,
    item_list_name: category.id ? category.name : category?.children?.[0]?.name,
    index,
    item_status_tags: product?.item_status_tags,
  }
}

export type ResultType = ':Ok' | ':Err'

export interface Result<T, E> {
  type: ResultType
  getValueSafe(): T | undefined
  getErrorSafe(): E | undefined
  getValue(defaultVal?: T): T
  getError(): E
  matches<U>(fn: Match<T, E, U>): U
}

export interface Match<T, E, U> {
  Ok: (value: T) => U
  Err: (value: E) => U
}

export interface OkResponse<T, E = never> extends Result<T, E> {
  type: ':Ok'
  getValue(defaultVal?: T): T
  getError(): never
  matches<U>(matchFn: Match<T, never, U>): U
}

export interface ErrResponse<T, E> extends Result<T, E> {
  type: ':Err'
  getValue(defaultVal?: T): T
  getError(): E
  match<U>(matchFn: Match<never, E, U>): U
}

export function adaptResult<T, E = never>(value: T): Result<T, E> {
  return {
    type: ':Ok',
    getValueSafe() {
      return value
    },
    getErrorSafe() {
      return undefined
    },
    getError() {
      throw new Error('Ok Result can not unwrap Err')
    },
    getValue(defaultValue?: T) {
      if (!value && defaultValue) {
        return defaultValue
      }
      return value
    },
    matches<U>(matchObject: Match<T, never, U>): U {
      return matchObject.Ok(value)
    },
  }
}

export function errResult<T, E>(err: E): Result<T, E> {
  return {
    type: ':Err',
    getErrorSafe() {
      return err
    },
    getValueSafe() {
      return undefined
    },
    getError() {
      return err
    },
    getValue(defaultVal?: T) {
      if (!defaultVal) {
        throw err
      }
      return defaultVal
    },
    matches<U>(matchObject: Match<never, E, U>): U {
      return matchObject.Err(err)
    },
  }
}

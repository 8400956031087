import { LineItem } from 'shared-types'
import { GTMEvent, RemoveFromCartDTO } from '~/lib'
import { adaptProduct } from './adaptProduct'

export const adaptRemoveFromCart = ({
  removedProduct,
}: {
  removedProduct: LineItem
}): RemoveFromCartDTO => {
  return {
    event: GTMEvent.REMOVE_FROM_CART,
    ecommerce: removedProduct
      ? {
          currency: removedProduct?.price?.value?.currencyCode,
          items: [adaptProduct({ product: removedProduct })],
        }
      : null,
  }
}

import { QueueElement, Resolver, State as QueueElementState } from '.'

export class PriorityQueue {
  readonly #items: QueueElement[]

  constructor() {
    this.#items = []
  }

  enqueue = (resolver: Resolver, priority: number) => {
    const qElement = new QueueElement(resolver, priority)
    let contain = false
    for (let i = 0; i < this.#items.length; i++) {
      if (this.#items[i].getPriority() > qElement.getPriority()) {
        this.#items.splice(i, 0, qElement)
        contain = true
        break
      }
    }
    if (!contain) {
      this.#items.push(qElement)
    }
    return qElement.getId()
  }

  throwElementFromQueue = (id: string) => {
    const index = this.#items.findIndex((item) => {
      return item.getId() === id
    })
    if (index === -1) {
      return
    }
    this.#items.splice(index, 1)
  }

  getItemById = (id: string) => {
    return this.#items.find((item) => {
      return item.getId() === id
    })
  }

  getItems = () => {
    return this.#items
  }

  dequeue = () => {
    if (this.isEmpty()) {
      return null
    }
    return this.#items.shift()
  }

  print = () => {
    return this.#items.map((item) => {
      return {
        id: item.getId(),
        data: item.getData(),
        state: item.getState(),
        priority: item.getPriority(),
      }
    })
  }

  isEmpty = () => {
    return this.#items.length === 0
  }

  isReady = () => {
    return this.#items.every((item) => {
      return item.getState() === QueueElementState.RESOLVED
    })
  }
}

import { GTMEvent } from '~/lib'
import { formatPriceForDataLayer } from '~/lib/gtm/helpers'

export const adaptAddToProductComparison = ({ products }) => {
  return {
    event: GTMEvent.ADD_TO_PRODUCT_COMPARISON,
    ecommerce: {
      currency: products?.[0]?.finalPrice?.currencyCode,
      items: products.map((product) => {
        return {
          item_id: product?.sku,
          item_name: product?.title,
          price: Number(
            formatPriceForDataLayer(product?.finalPrice?.centAmount)
          ),
          item_brand: product?.brandName,
          item_category: product?.category1Name,
          item_category2: product?.category2Name,
          item_category3: product?.category3Name,
          item_category4: product?.category4Name,
          item_category5: product?.category5Name,
          item_list_id: product?.listId,
          item_list_name: product?.listName,
          item_status_tags: product?.item_status_tags,
          quantity: 1,
          in_stock: product?.inStock ? 'Yes' : 'No',
        }
      }),
    },
  }
}

import { Order } from 'shared-types'
import { GTMEvent, PurchaseDTO } from '~/lib'
import { formatPriceForDataLayer } from '~/lib/gtm/helpers'
import { adaptProduct } from './adaptProduct'

export const adaptPurchase = (order: Order): PurchaseDTO => {
  return {
    event: GTMEvent.PURCHASE,
    ecommerce: {
      transaction_id: order?.orderNumber ?? '',
      value: Number(formatPriceForDataLayer(order.value)) ?? 0,
      tax: Number(formatPriceForDataLayer(order.tax)) ?? 0,
      shipping: Number(formatPriceForDataLayer(order.shipping)) ?? 0,
      currency: order.lineItems[0].price.value.currencyCode ?? '',
      coupon: order.coupon ?? '',
      insurance: Number(order?.insurance),
      payment_method: order.paymentMethod ?? '',
      items:
        order.lineItems.map((product) => {
          return adaptProduct({
            product,
          })
        }) ?? [],
    },
  }
}

import { Session } from 'next-auth'
import { CustomerList } from 'shared-types'
import { IProjectsClient } from '~/types/Client'
import { PaginationInfo } from '~/providers/PaginationProvider/PaginationProvider'
import { ProjectList } from '~/sections/MakerHubAllLanding/MakerHubAllLanding.types'
import { request } from './request'

export type GetProjectListResponse = PaginationInfo & { projects: ProjectList }

export class ProjectsClient implements IProjectsClient {
  getProjectList = async (
    params,
    session: Session
  ): Promise<GetProjectListResponse> => {
    const response = await request<GetProjectListResponse>(
      {
        url: `/bff/projects/list?${params}`,
        method: 'GET',
      },
      session
    )
    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }

  updateProjectBookmark = async (
    params,
    session: Session
  ): Promise<CustomerList> => {
    const response = await request<CustomerList>(
      {
        url: `/bff/bookmark-project`,
        method: 'POST',
        body: params,
      },
      session
    )
    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }
}

import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocalStorage } from 'react-use'
import { useIsServerEffect } from '@overdose/components'
import dynamic from 'next/dynamic'
import { Product } from 'shared-types'
import { GTM, GTMEvent } from '~/lib'
import { CompareProductsProviderProps } from './CompareProductsProvider.types'

const CompareProductsSnackbar = dynamic(
  async () => {
    const mod = await import('~/components/CompareProductsSnackbar')
    return mod.CompareProductsSnackbar
  },
  { ssr: false }
)

const COMPARE_LIST_KEY = 'compare-list'
export const CompareProductsContext =
  createContext<CompareProductsProviderProps>(null)

declare global {
  interface Window {
    addToCompare?: (skus: string[]) => void
  }
}

export const CompareProductsProvider = ({ children }: PropsWithChildren) => {
  const [productIds, setProductIds] = useLocalStorage<string[]>(
    COMPARE_LIST_KEY,
    []
  )

  const isSSR = useIsServerEffect()
  const [selectedProducts, setSelectedProducts] = useState([])

  useEffect(() => {
    if (!isSSR) {
      const isCompareListNull =
        localStorage.getItem(COMPARE_LIST_KEY) === 'null'
      if (isCompareListNull) {
        localStorage.setItem(COMPARE_LIST_KEY, '[]')
        setProductIds([])
      }
    }
  }, [isSSR, setProductIds])

  useEffect(() => {
    if (!isSSR) {
      document.onvisibilitychange = () => {
        const lists = JSON.parse(
          localStorage.getItem(COMPARE_LIST_KEY)
        ) as string[]

        setProductIds(lists)
      }
    }
  }, [isSSR, setProductIds])

  const maxProductsCount = Number('4')

  const handleToggleProduct = useCallback(
    (product: Product) => {
      const productIdList = [...productIds]
      const { sku: productId } = product
      const index = productIdList.indexOf(productId)
      if (index > -1) {
        productIdList.splice(index, 1)
      } else {
        productIdList.push(productId)
      }
      setProductIds(productIdList.slice(-maxProductsCount))
      productIdList.includes(product.sku)
        ? setSelectedProducts((prev) => {
            return prev.concat(product)
          })
        : setSelectedProducts((prev) => {
            return prev.filter((item) => {
              return item.sku !== product.sku
            })
          })
    },
    [productIds, setProductIds, maxProductsCount]
  )

  const isProductInCompare = useCallback(
    (sku: string): boolean => {
      return productIds?.indexOf(sku) > -1
    },
    [productIds]
  )

  const handleRemoveProduct = useCallback(
    (productId: string) => {
      const productIdList = [...productIds]
      const index = productIdList.indexOf(productId)
      if (index > -1) {
        productIdList.splice(index, 1)
      }
      setProductIds(productIdList)
    },
    [productIds, setProductIds]
  )

  const canAddRemoveToCompare = useCallback(
    (sku: string): boolean => {
      return (
        !isSSR &&
        (productIds?.length < maxProductsCount || productIds?.indexOf(sku) > -1)
      )
    },
    [productIds, maxProductsCount, isSSR]
  )

  const handleClearAllProduct = useCallback(() => {
    setProductIds([])
    setSelectedProducts([])
  }, [setProductIds])

  const dispatchAddToProductComparison = useCallback((products) => {
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.ADD_TO_PRODUCT_COMPARISON, { products })
  }, [])

  useEffect(() => {
    window.addToCompare = (skus: string[]) => {
      setProductIds(skus)
      window.location.href = `/compare?products=${skus
        ?.filter((_, index) => {
          return index < maxProductsCount
        })
        .join('|')}`
    }
  }, [maxProductsCount, setProductIds])

  const value: CompareProductsProviderProps = useMemo(() => {
    return {
      selectedProductIds: productIds,
      productsCount: productIds?.length,
      maxProductsCount,
      toggleProduct: handleToggleProduct,
      clearAllProducts: handleClearAllProduct,
      isProductInCompare,
      canAddRemoveToCompare,
      selectedProductsList: selectedProducts,
      removeProduct: handleRemoveProduct,
      dispatchAddToProductComparison,
    }
  }, [
    productIds,
    maxProductsCount,
    handleToggleProduct,
    handleClearAllProduct,
    isProductInCompare,
    canAddRemoveToCompare,
    selectedProducts,
    handleRemoveProduct,
    dispatchAddToProductComparison,
  ])

  return (
    <CompareProductsContext.Provider value={value}>
      {children}
      <CompareProductsSnackbar />
    </CompareProductsContext.Provider>
  )
}

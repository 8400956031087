import { Cart } from 'shared-types'
import { GTMEvent, AddToCartDTO } from '~/lib'
import { adaptProduct } from './adaptProduct'

export const adaptAddToCart = ({ cart }: { cart: Cart }): AddToCartDTO => {
  const addedCartItems = cart?.lineItems
  return {
    event: GTMEvent.ADD_TO_CART,
    ecommerce: cart
      ? {
          currency: cart?.totalPrice?.currencyCode,
          items: addedCartItems.map((item) => {
            return adaptProduct({
              product: item,
              isUseTotalQuantityPriceFormat: true,
            })
          }),
        }
      : null,
  }
}

import { GTMEvent, AddPaymentInfoDTO } from '~/lib'

import { adaptProduct } from './adaptProduct'

export const adaptAddPaymentInfo = ({
  items,
  paymentType,
}): AddPaymentInfoDTO => {
  return {
    event: GTMEvent.ADD_PAYMENT_INFO,
    ecommerce: {
      currency: items?.[0]?.price?.value?.currencyCode,
      payment_type: paymentType,
      items: items?.map((product) => {
        return adaptProduct({
          product,
        })
      }),
    },
  }
}

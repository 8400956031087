import { CSSProperties, ReactNode } from 'react'

import { ThemableComponentProps } from '@overdose/components/src/types/common'
import theme from '../styles/Typography.module.scss'

export enum TypographyVariant {
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Heading4 = 'heading4',
  Heading5 = 'heading5',
  Heading6 = 'heading6',
  BodyXLarge = 'bodyXLarge',
  BodyLarge = 'bodyLarge',
  BodyRegular = 'bodyRegular',
  BodySmall = 'bodySmall',
  BodyXLargeBold = 'bodyXLargeBold',
  BodyLargeBold = 'bodyLargeBold',
  BodyRegularBold = 'bodyRegularBold',
  BodySmallBold = 'bodySmallBold',
  BodyXLargeMedium = 'bodyXLargeMedium',
  BodyLargeMedium = 'bodyLargeMedium',
  BodyRegularMedium = 'bodyRegularMedium',
  BodySmallMedium = 'bodySmallMedium',
  BodyRegularExtraLineHeight = 'bodyRegularExtraLineHeight',
  BodySmallExtraLineHeight = 'bodySmallExtraLineHeight',
  BodyRegularExtraLineHeightBold = 'bodyRegularExtraLineHeightBold',
  BodySmallExtraLineHeightBold = 'bodySmallExtraLineHeightBold',
  BodyRegularExtraLineHeightMedium = 'bodyRegularExtraLineHeightMedium',
  BodySmallExtraLineHeightMedium = 'bodySmallExtraLineHeightMedium',
}

export type Weight = 'Bold' | 'Medium' | 'Regular'

export enum TypographyTag {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  p = 'p',
  div = 'div',
  span = 'span',
}

export interface TypographyProps extends ThemableComponentProps<typeof theme> {
  tag: TypographyTag
  variant: TypographyVariant
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

import React from 'react'
import { IconX } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from '~/components/Button'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import styles from './Notification.module.css'
import { NotificationProps } from './Notification.types'

export const Notification = ({
  id,
  type = 'default',
  children,
  onClick,
  onClose,
}: NotificationProps) => {
  return (
    <div
      className={classNames(
        'rounded-md flex items-center gap-x-2.5 px-4 py-6',
        styles.root,
        {
          [styles.default]: type === 'default',
          [styles.primary]: type === 'primary',
          [styles.info]: type === 'info',
          [styles.error]: type === 'error',
          [styles.success]: type === 'success',
        }
      )}>
      <div className='flex-1'>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyRegular}>
          {children}
        </Typography>
      </div>
      <div className='flex items-center gap-x-4'>
        {onClick && (
          <Button
            variant='ghost'
            theme={{ root: styles.button }}
            onClick={onClick}>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodySmallBold}>
              Show
            </Typography>
          </Button>
        )}
        <Button
          variant='ghost'
          theme={{ root: styles.button }}
          onClick={() => {
            return onClose?.(id)
          }}
          iconOnly
          icon={<IconX size={16} />}
        />
      </div>
    </div>
  )
}

import { Session } from 'next-auth'
import { ProjectSliderCardProps } from '~/components/ProjectCardSlider/ProjectCardSlider.types'
import { IBladeClient } from '~/types/Client'
import { request } from './request'

export class BladeClient implements IBladeClient {
  getProjects = async (
    params,
    session: Session
  ): Promise<ProjectSliderCardProps[]> => {
    const response = await request<ProjectSliderCardProps[]>(
      {
        url: `/bff/projects/makerHubBladeProjects?${params}`,
        method: 'GET',
      },
      session
    )
    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }
}

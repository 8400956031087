import { Product } from 'shared-types'
import { GTMEvent, ViewItemDTO } from '~/lib'
import { formatPriceForDataLayer } from '~/lib/gtm/helpers'

export const adaptViewItem = ({
  product,
}: {
  product: Product
}): ViewItemDTO => {
  return {
    event: GTMEvent.VIEW_ITEM,
    ecommerce: {
      currency: product?.finalPrice?.currencyCode,
      items: [
        {
          item_id: product?.sku,
          item_name: product?.title,
          price: Number(
            formatPriceForDataLayer(product?.finalPrice?.centAmount)
          ),
          item_brand: product?.brandName,
          item_category: product?.category1Name,
          item_category2: product?.category2Name,
          item_category3: product?.category3Name,
          item_category4: product?.category4Name,
          item_category5: product?.category5Name,
          in_stock: product?.inStock ? 'Yes' : 'No',
          item_status_tags: product?.item_status_tags,
        },
      ],
    },
  }
}

import {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { UpdateCartRequest } from 'shared-types'
import { useCart } from '~/hooks'
import { CartDrawerProviderProps } from './CartDrawerProvider.types'

export const CartDrawerProviderContext = createContext<CartDrawerProviderProps>(
  {}
)

declare global {
  interface Window {
    addToCart?: (payload: UpdateCartRequest[]) => Promise<void>
  }
}

export const CartDrawerProvider = ({ children }: PropsWithChildren) => {
  const [isCartOpen, setIsCartOpen] = useState(false)

  const closeCartDrawer = () => {
    return setIsCartOpen(false)
  }
  const openCartDrawer = () => {
    return setIsCartOpen(true)
  }

  const value = useMemo(() => {
    return {
      isCartOpen,
      closeCartDrawer,
      openCartDrawer,
    }
  }, [isCartOpen])

  const { addToCart } = useCart()

  useEffect(() => {
    if (addToCart && addToCart.mutate) {
      window.addToCart = (payload: UpdateCartRequest[]) => {
        return new Promise<void>((resolve, reject) => {
          addToCart.mutate(payload, {
            onSuccess: () => {
              return resolve(openCartDrawer())
            },
            onError: (error) => {
              return reject(error)
            },
          })
        })
      }
    }
  }, [addToCart])

  return (
    <CartDrawerProviderContext.Provider value={value}>
      {children}
    </CartDrawerProviderContext.Provider>
  )
}

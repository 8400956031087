import { GTMEvent } from '../../declarations'

export const adaptServerSideTracking = () => {
  const now = new Date()
  const timestampNow = now.getTime()
  const randomInt = Math.floor(Math.random() * Number('100000000000000'))
  return {
    event: GTMEvent.SERVER_SIDE_TRACKING,
    timestampNow,
    randomInt,
  }
}

import React, { HTMLAttributes, forwardRef, useCallback } from 'react'
import { LinkProps, useIsServerEffect } from '@overdose/components'
import { LinkProps as NextLinkProps } from 'next/dist/client/link'
import NextLink from 'next/link'

export type CustomLinkProps = LinkProps &
  Omit<NextLinkProps, 'href'> &
  HTMLAttributes<HTMLElement>

/**
 *  A custom link component passed to AppProvider from @overdose/components.
 *  This component should not be included directly in your component. Instead,
 *  import Link from @overdose/components.
 */
export const CustomLink = forwardRef<HTMLAnchorElement, CustomLinkProps>(
  (props, ref) => {
    const isSSR = useIsServerEffect()
    const {
      external,
      to,
      as,
      replace,
      scroll,
      shallow,
      prefetch,
      locale,
      passHref = true,
      ariaLabel,
      children,
      ...anchorProps
    } = props

    const linkProps = {
      href: to,
      as,
      replace,
      scroll,
      shallow,
      prefetch,
      locale,
      passHref,
    }

    const handleOpenChat = useCallback(() => {
      if (window?.hasOwnProperty('zE') && !isSSR) {
        window.zE('messenger', 'open', undefined)
      }
    }, [isSSR])

    if (to?.includes('#chat')) {
      return (
        <button onClick={handleOpenChat} {...anchorProps} type='button'>
          {children}
        </button>
      )
    }

    if (external) {
      return (
        <a
          aria-label={ariaLabel}
          target='_blank'
          rel='noopener noreferrer'
          href={to}
          {...anchorProps}
          ref={ref}>
          {children}
        </a>
      )
    }

    return (
      <NextLink {...linkProps} legacyBehavior>
        <a aria-label={ariaLabel} {...anchorProps} ref={ref}>
          {children}
        </a>
      </NextLink>
    )
  }
)

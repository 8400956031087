import { FeaturedProductSummary } from 'shared-types'
import { GTMEvent, ViewPromotionDTO } from '~/lib'
import { adaptPromotionImpression } from './adaptPromotionImpression'

export const adaptViewPromotion = ({
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  product,
  index = 0,
}: FeaturedProductSummary): ViewPromotionDTO => {
  return {
    event: GTMEvent.VIEW_PROMOTION,
    ecommerce: adaptPromotionImpression({
      promotionId,
      promotionName,
      creativeName,
      creativeSlot,
      product,
      index,
    }),
  }
}

import { JWT, Session } from 'next-auth'
import {
  CheckCustomerExistsResponse,
  NewPasswordRequest,
  Result,
} from 'shared-types'
import { ApiError } from '../helpers/apiError'
import { IAuthClient } from '../types/Client'
import { request } from './request'

export class AuthClient implements IAuthClient {
  accessToken = (): Promise<Result<JWT, ApiError>> => {
    return request({
      method: 'GET',
      url: '/bff/auth/accessToken',
    })
  }

  isDisabled = (token): Promise<Result<boolean, ApiError>> => {
    return request({
      method: 'GET',
      url: '/bff/account/is-disabled',
      headers: {
        Authorization: token,
      },
    })
  }

  signin = (
    payloaod: {
      username: string
      password: string
    },
    session: Session,
    referer: string
  ): Promise<Result<JWT, ApiError>> => {
    return request(
      {
        headers: {
          referer,
        },
        method: 'POST',
        url: '/bff/auth/signin',
        body: payloaod,
      },
      session
    )
  }

  signup = (
    payload: {
      email: string
      firstName: string
      lastName: string
      password: string
      phone: string
    },
    session: Session
  ): Promise<Result<JWT, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: '/bff/account/customer',
        body: payload,
      },
      session
    )
  }

  forgotPassword = (
    payload: {
      email: string
    },
    session: Session
  ): Promise<Result<string, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: '/bff/account/forgot-password',
        body: payload,
      },
      session
    )
  }

  resetPassword = (
    payload: NewPasswordRequest,
    session: Session
  ): Promise<Result<string, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: '/bff/customers/reset-password',
        body: payload,
      },
      session
    )
  }

  checkCustomerExists = (
    email: string,
    session: Session
  ): Promise<Result<CheckCustomerExistsResponse, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: '/bff/account/checkCustomerExists',
        body: { email },
      },
      session
    )
  }
}

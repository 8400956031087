import { useSession as useNextAuthSession } from 'next-auth/react'

export const useSession = () => {
  const { status, data, update } = useNextAuthSession()

  if (data && !data.updateSession) {
    data.updateSession = update
  }

  return {
    session: data,
    update,
    status,
  }
}

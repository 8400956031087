import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { CustomerList } from 'shared-types'
import { useSession } from '~/hooks'
import { Client } from '~/customClients/client'
import { CustomerListsProviderProps } from './CustomerListsProvider.types'

const CustomerListsDrawer = dynamic(
  async () => {
    const mod = await import('~/components/CustomerListsDrawer')
    return mod.CustomerListsDrawer
  },
  { ssr: false }
)

export const CustomerListsProviderContext =
  createContext<CustomerListsProviderProps>(null)

export const CustomerListsProvider = ({ children }: PropsWithChildren) => {
  const { session } = useSession()
  const [isOpen, setIsOpen] = useState(false)
  const [customerLists, setCustomerLists] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const router = useRouter()
  const [selectedList, setSelectedList] = useState<CustomerList>()

  const closeDrawer = useCallback(() => {
    return setIsOpen(false)
  }, [])

  const handleCustomerListsResponse = useCallback(
    (lists: CustomerList[]) => {
      if (selectedId && !!lists?.length) {
        const selected = lists.find((li) => {
          return li.id === selectedList?.id
        })
        setSelectedList(selected)
      }
      setCustomerLists(lists || [])
    },
    [selectedId, selectedList?.id]
  )

  const getLists = useCallback(async () => {
    if (!session) {
      return false
    }

    const res = await Client.lists.getLists([], session)
    if (res.getErrorSafe()) {
      setCustomerLists([])
      return false
    }

    handleCustomerListsResponse(res.getValueSafe())
    return true
  }, [handleCustomerListsResponse, session])

  const openDrawer = useCallback(
    async (id: string = null) => {
      await getLists()
      setSelectedId(id)
      return setIsOpen(true)
    },
    [getLists]
  )

  const value = useMemo(() => {
    return {
      isOpen,
      closeDrawer,
      openDrawer,
      setCustomerLists,
    }
  }, [isOpen, openDrawer, closeDrawer])

  useEffect(() => {
    const handleRouteChange = () => {
      closeDrawer()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router, closeDrawer])

  return (
    <CustomerListsProviderContext.Provider value={value}>
      {children}
      <CustomerListsDrawer
        open={isOpen}
        onClose={closeDrawer}
        lists={customerLists}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        getLists={getLists}
      />
    </CustomerListsProviderContext.Provider>
  )
}

import { createContext, useEffect, useMemo, useState } from 'react'
import { DEFAULT_COUNTRY_CODE_AU } from '~/constants/countries'
import {
  ForterEvent,
  PageProviderContext,
  PageProviderProps,
} from './PageProvider.types'

export const PageContext = createContext<PageProviderContext>(null)

export const PageProvider = ({ children, ...page }: PageProviderProps) => {
  const [forterToken, setForterToken] = useState<string>()
  useEffect(() => {
    const forterListener = (evnt: ForterEvent) => {
      if (evnt.detail) {
        const token = evnt.detail
        setForterToken(token)
      }
    }

    document.addEventListener('ftr:tokenReady', forterListener)
    return () => {
      document.removeEventListener('ftr:tokenReady', forterListener)
    }
  }, [])

  const value = useMemo(() => {
    return {
      ...page,
      countryCode: page.countryCode?.toUpperCase() || DEFAULT_COUNTRY_CODE_AU,
      contactUsLink: page.contactUsLink,
      faqLink: page.faqLink,
      forterToken,
    }
  }, [forterToken, page])
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

import {
  GTMEvent,
  ViewSearchResultsDTO,
  ProductImpression,
} from '~/lib/gtm/declarations'
import { adaptProductImpression } from './adaptProductImpression'

export const adaptViewSearchResults = ({
  products,
  searchTerm,
  searchFilters,
  searchResultCount,
  searchType,
}): ViewSearchResultsDTO => {
  return {
    event: GTMEvent.VIEW_SEARCH_RESULTS,
    search_term: searchTerm,
    search_filters: searchFilters,
    search_result_count: searchResultCount,
    search_type: searchType,
    ecommerce: {
      currency: products?.[0]?.regularPrice?.currencyCode,
      items: (function () {
        const productImpressions: Array<ProductImpression> = []
        products?.forEach((product, index) => {
          productImpressions.push(adaptProductImpression({ product, index }))
        })
        return productImpressions
      })(),
    },
  }
}

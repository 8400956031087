export enum GTMEvent {
  PAGE_LOAD = 'page_load',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  SUBSCRIBE_TO_NEWSLETTER = 'subscribe_to_newsletter',
  DEACTIVATE_ACCOUNT = 'deactivate_account',
  CREATE_ACCOUNT = 'create_account',
  LOGIN = 'login',
  SELECT_CHECKOUT_OPTION = 'select_checkout_option',
  VIEW_ITEM = 'view_item',
  SELECT_ITEM = 'select_item',
  VIEW_ITEM_LIST = 'view_item_list',
  CART_CONTENTS_PUSH = 'cart_contents_push',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  VIEW_SEARCH_RESULTS = 'view_search_results',
  PURCHASE = 'purchase',
  STORE_FINDER = 'store_finder',
  CHECK_STORE_AVAILABILITY = 'check_store_availability',
  VIEW_PROMOTION = 'view_promotion',
  SELECT_PROMOTION = 'select_promotion',
  SUBMIT_RETURN_FROM = 'submit_return_form',
  NOTIFY_BACK_IN_STOCK = 'notify_back_in_stock',
  CLEAR_ECOMMERCE = 'clear_ecommerce',
  ADD_TO_LIST = 'add_to_list',
  CREATE_LIST = 'create_list',
  ADD_TO_PRODUCT_COMPARISON = 'add_to_product_comparison',
  USER_IDENTIFY = 'user_identify',
  SERVER_SIDE_TRACKING = 'eventIDGenerated',
}

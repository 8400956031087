import { DeliveryType } from './checkout'
import { Address, BaseMoney, Customer as CTCustomer } from './commercetools'
import { DeliveryStatus } from './order'

export interface CustomerCommunicationSubscription {
  list: string
  name: string
  field: string
  value: string
  key: string
}

export interface CustomerCommunicationPreferences {
  hasOptedOutOfEmail: boolean
  subscriptions: CustomerCommunicationSubscription[]
}

export interface CustomerDetails extends Pick<Customer, 'firstName' | 'lastName' | 'email'> {
  version: number
  firstName?: string
  lastName?: string
  addresses?: CustomerAddress[]
}

type AddressUnion =
  | 'email'
  | 'country'
  | 'state'
  | 'city'
  | 'streetName'
  | 'streetNumber'
  | 'postalCode'
  | 'company'
  | 'firstName'
  | 'lastName'
  | 'phone'

export interface CustomerAddress extends Pick<Address, AddressUnion>, Record<string, unknown> {
  id?: string
  suburb?: string
  addressLine1?: string
  addressLine2?: string
  phone?: string
  isDefault?: boolean
  latitude?: string
  longitude?: string
  label?: string
  placeId?: string
  subpremise?: string
  isManual?: boolean
}

export const enum CreditCardType {
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  DISCOVER = 'discover',
}

export interface CustomerSavedPaymentMethod {
  cardName: string
  cardNumber: string
  cardType: CreditCardType
  expiryDate: string
  isDefault?: boolean
  id: string
}

export interface CustomerGroup {
  typeId: string
  key?: string
}

export interface CustomerSavedGiftCard {
  cardName: string
  expiryDate: string
  remainingPrice: number
  id: string
}

export interface OrderDeliveryCardProps {
  productsCount: number
  orderNumber: string
  deliveryType?: DeliveryType
  status?: DeliveryStatus
  predictedDeliveryAtRange?: {
    from?: Date
    to?: Date
  }
}

export interface CustomerMyRewardsProgressCardProps {
  currentPoints: number
  tiers: Array<{ points: number }>
}

export interface CustomerSavedVoucherCardProps {
  onClick?: () => void
  title: string
  condition: string
  expiresAt?: string
}

export interface Customer
  extends Pick<
    CTCustomer,
    'id' | 'email' | 'firstName' | 'lastName' | 'salutation' | 'dateOfBirth' | 'isEmailVerified' | 'companyName'
  > {
  version?: number
  customerGroup?: CustomerGroup
  preferredStoreKey?: string
  orderCount?: 0
  customerAddresses?: CustomerAddress[]
  customerCommunicationPreferences?: CustomerCommunicationPreferences
  customerSavedPaymentMethods?: CustomerSavedPaymentMethod[]
  customerSavedGiftCards?: CustomerSavedGiftCard
  phoneNumber?: string
  rewards?: {
    rewardPoints?: number
    rewardTiers?: number[]
    remainingPoints?: number
  }[]
  rewardPoints?: number
  rewardTiers?: number[]
  customerRecentOrders?: OrderDeliveryCardProps[]
  customerRewards?: CustomerMyRewardsProgressCardProps
  customerSavedVouchers?: CustomerSavedVoucherCardProps[]
  rewardAmount?: BaseMoney
  passwordLastModifiedDate?: string
  customerOrders?: unknown
  deliveryPreviewPostcode?: string
  deliveryPreviewCity?: string
  statusCode?: number
  isSignedIn?: boolean
  emailSha256?: string
  firstnameSha256?: string
  lastnameSha256?: string
  citySha256?: string
  zipSha256?: string
  phoneSha256?: string
  countrycodeSha256?: string
  stateSha256?: string
  addressSha256?: string
}

export interface ErrorData {
  message: string
  statusCode: number
}

export interface CustomerWithError {
  customer?: Customer
  error?: ErrorData
}

export interface GetCustomerResponse {
  customer: Customer
}

export interface CustomerAddressRequest {
  id?: string
  country?: string
  salutation?: string
  firstName?: string
  lastName?: string
  address1?: string
  address2?: string
  companyName?: string
  postcode?: string
  state?: string
  city?: string
  longitude?: string
  latitude?: string
  isResidential?: boolean
  isDefault?: boolean
  suburb?: string
  fullName?: string
  label?: string
}

export interface CheckCustomerExistsRequest {
  email: string
}

export interface CheckCustomerExistsResponse {
  exists: boolean
}

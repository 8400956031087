import { PropsWithChildren, createContext, useCallback, useMemo } from 'react'
import { useNotificationsContext } from '@overdose/components'
import dynamic from 'next/dynamic'
import { Notification, NotificationProps } from '~/components'
import { ToastProviderContext } from './ToastProvider.types'

const NotificationsProvider = dynamic(async () => {
  const mod = await import('@overdose/components')
  return mod.NotificationsProvider
})

export const ToastContext = createContext<ToastProviderContext>(null)

const ToastDeligate = ({ children }: PropsWithChildren) => {
  const { showNotification, hideNotification, clean, cleanQueue } =
    useNotificationsContext()

  const handleShowNotification = useCallback(
    (notification: NotificationProps) => {
      showNotification({
        id: notification.id,
        theme: {
          notification: 'block p-0 border-0 bg-transparent',
          description: 'text-inherit',
          icon: 'hidden',
        },
        message: <Notification {...notification} />,
        autoClose: notification.autoClose,
        disallowClose: true,
      })
    },
    [showNotification]
  )

  const handleClearAllNotification = useCallback(() => {
    cleanQueue()
    clean()
  }, [clean, cleanQueue])

  const value = useMemo(() => {
    return {
      showNotification: handleShowNotification,
      closeNotification: hideNotification,
      clearAll: handleClearAllNotification,
    }
  }, [handleClearAllNotification, handleShowNotification, hideNotification])

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

export const ToastProvider = ({ children }: PropsWithChildren) => {
  return (
    <NotificationsProvider>
      <ToastDeligate>{children}</ToastDeligate>
    </NotificationsProvider>
  )
}

import { FeaturedProductSummary } from 'shared-types'
import { GTMEvent, SelectPromotionDTO } from '~/lib/gtm/declarations'
import { adaptPromotionImpression } from './adaptPromotionImpression'

export const adaptSelectPromotion = ({
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  product,
  index = 0,
}: FeaturedProductSummary): SelectPromotionDTO => {
  return {
    event: GTMEvent.SELECT_PROMOTION,
    ecommerce: adaptPromotionImpression({
      promotionId,
      promotionName,
      creativeName,
      creativeSlot,
      product,
      index,
    }),
  }
}

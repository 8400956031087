import { Compose, composeThemeFromProps } from '@css-modules-theme/react'
import { Button as BaseButton, BaseButtonProps } from '@overdose/components'

import classNames from 'classnames'
import styles from './Button.module.scss'

interface ButtonProps extends BaseButtonProps {
  className?: string
}

export function Button({ className = '', ...props }: ButtonProps) {
  const { children, iconOnly, theme } = props
  const defaultStyles: BaseButtonProps['theme'] = {
    root: classNames(styles.root, className, {
      [styles.iconOnly]: iconOnly,
    }),
    lg: styles.lg,
    md: styles.md,
    sm: styles.sm,
    xs: styles.xs,
    ghost: styles.ghost,
    disabled: styles.disabled,
    loading: styles.loading,
    secondary: styles.secondary,
    link: styles.link,
  }

  const customStyles = composeThemeFromProps(
    defaultStyles,
    { theme },
    { compose: Compose.Merge }
  )

  return (
    <BaseButton
      {...props}
      theme={customStyles}
      noHover={props.variant === 'ghost'}>
      {children}
    </BaseButton>
  )
}

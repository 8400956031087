/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames'
import { getImageSrcSet } from '~/helpers'
import { ImageProps } from './Image.types'

export const Image = ({
  priority = false,
  className,
  fill,
  src,
  sources,
  addSrcSet = true,
  srcSet,
  ...rest
}: ImageProps) => {
  const isStaticImage = typeof src === 'object'
  const canGenerateSrcSet =
    !isStaticImage &&
    !src?.includes('/_next/static') &&
    src?.includes('https://')

  return (
    <picture className='h-full'>
      {sources}
      <img
        {...rest}
        src={isStaticImage ? src?.src : src}
        fetchPriority={priority ? 'high' : 'auto'}
        loading={priority ? 'eager' : 'lazy'}
        className={classNames(className, 'h-full', {
          'h-full w-full absolute': fill,
        })}
        srcSet={`${srcSet ?? ''} ${
          addSrcSet && canGenerateSrcSet
            ? getImageSrcSet(src, [
                {
                  intrinsicImageSize: '100',
                  width: '100',
                },
                {
                  intrinsicImageSize: '200',
                  width: '200',
                },
                {
                  intrinsicImageSize: '300',
                  width: '300',
                },
                {
                  intrinsicImageSize: '400',
                  width: '400',
                },
                {
                  intrinsicImageSize: '500',
                  width: '500',
                },
                {
                  intrinsicImageSize: '600',
                  width: '600',
                },
                {
                  intrinsicImageSize: '700',
                  width: '700',
                },
                {
                  intrinsicImageSize: '800',
                  width: '800',
                },
                {
                  intrinsicImageSize: '900',
                  width: '900',
                },
                {
                  intrinsicImageSize: '1000',
                  width: '1000',
                },
              ])
            : ''
        }`}
      />
    </picture>
  )
}

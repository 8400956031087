export enum HTTPStatus {
  Ok = 200,
  BadRequest = 400,
  InternalServerError = 500,
  Unauthorized = 401,
  NotFound = 404,
  MultipleChoices = 300,
  PermanentRedirect = 301,
  TemporaryRedirect = 302,
}

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { JWT, Session } from 'next-auth'
import { Result, adaptResult, errResult } from 'shared-types'

import { ApiError } from '../helpers/apiError'
import { ApiRequestOptions } from '../types/ApiRequestOptions'
import { ApiResult } from '../types/ApiResult'
import { CancellablePromise } from '../types/CancellablePromise'
import { Client } from './client'
import { HTTPStatus } from './httpStatus'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
})

const getQuery = (options: ApiRequestOptions): string => {
  const { query } = options
  if (typeof query === 'string') {
    return `?${query}`
  }
  if (typeof query === 'object') {
    return `?${new URLSearchParams(query as Record<string, string>).toString()}`
  }
  return ''
}

const cacheError = (options: ApiRequestOptions, result: ApiResult) => {
  const errors: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
  }

  const error = errors[result.status]
  if (error || !result.ok) {
    throw new ApiError(options, result, error || 'Unknown Error')
  }
}

const refreshToken = async (
  refreshToken: string
): Promise<Result<JWT, Error>> => {
  if (!refreshToken) {
    return Client.auth.accessToken()
  }
  try {
    const response = await axiosInstance.post(
      `/bff/auth/refreshToken/${refreshToken}`
    )
    if (response.status === HTTPStatus.Ok) {
      return adaptResult(response.data)
    }
  } catch (error) {
    console.error('Error refreshing token:', error)
  }
  return errResult(new Error('Error generating refresh token'))
}

const getAxiosResponseOrThrowError = (error) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error
    if (axiosError.response) {
      return axiosError.response
    }
    throw error
  } else {
    throw error
  }
}

export const request = async <T>(
  options: ApiRequestOptions,
  session?: Session
): Promise<Result<T, ApiError>> => {
  return new CancellablePromise(async (resolve, _reject, cancel) => {
    try {
      const { url, headers = {}, body, method } = options
      const query = getQuery(options)
      const authHeaders: Record<string, string> = {
        'Content-Type': 'application/json',
      }

      if (session?.token) {
        authHeaders.authorization = `Bearer ${session.token.accessToken}`
        if (session.token.storeKey) {
          authHeaders.storeKey = session.token.storeKey
        }
        if (session.token.deliveryPostcode) {
          authHeaders['delivery-postcode'] = session.token.deliveryPostcode
        }
        if (session.token.deliveryCity) {
          authHeaders['delivery-city'] = session.token.deliveryCity
        }
      }

      const axiosConfig: AxiosRequestConfig = {
        url: `${url}${query}`,
        method,
        headers: { ...authHeaders, ...headers },
        data: body,
      }

      const cancelTokenSource = axios.CancelToken.source()
      axiosConfig.cancelToken = cancelTokenSource.token

      let response
      try {
        response = await axiosInstance(axiosConfig)
      } catch (error) {
        response = getAxiosResponseOrThrowError(error)
      }

      const now = dayjs()
      const expiryDate = dayjs(session?.token?.expiryDate)

      if (
        response.status === HTTPStatus.Unauthorized ||
        (session?.token?.isLoggedIn &&
          response.status === HTTPStatus.InternalServerError)
      ) {
        const shouldLogout =
          !expiryDate.isBefore(now) ||
          response.status === HTTPStatus.InternalServerError
        const sessionResponse = await refreshToken(
          shouldLogout ? undefined : session?.token?.refreshToken
        )

        await session.updateSession({
          ...session,
          token: {
            ...sessionResponse.getValue(),
          },
        })

        if (sessionResponse.getValue()) {
          authHeaders.authorization = `Bearer ${
            sessionResponse.getValue().accessToken
          }`
        }

        axiosConfig.headers = { ...axiosConfig.headers, ...authHeaders }

        try {
          response = await axiosInstance(axiosConfig)
        } catch (error) {
          response = getAxiosResponseOrThrowError(error)
        }
      }

      const apiResult: ApiResult<T> = {
        ok:
          response.status >= HTTPStatus.Ok &&
          response.status < HTTPStatus.MultipleChoices,
        status: response.status,
        url,
        body: response.data,
      }

      cancel(() => {
        cancelTokenSource.cancel('Operation canceled by the user.')
      })

      cacheError(options, apiResult)
      resolve(adaptResult(apiResult.body))
    } catch (err) {
      resolve(errResult(err))
    }
  })
}

import React from 'react'
import { Typography as BaseTypography } from '@overdose/components'
import classNames from 'classnames'

import styles from './Typography.module.scss'
import { TypographyProps, TypographyVariant } from './Typography.types'

const variantMap = {
  [TypographyVariant.Heading1]: 'pageheading',
  [TypographyVariant.Heading2]: 'heading',
  [TypographyVariant.Heading3]: 'subheading',
  [TypographyVariant.Heading4]: 'displayExtraLarge',
  [TypographyVariant.Heading5]: 'displayLarge',
  [TypographyVariant.Heading6]: 'displayMedium',
  [TypographyVariant.BodyXLarge]: 'body',
  [TypographyVariant.BodyLarge]: 'body',
  [TypographyVariant.BodyRegular]: 'body',
  [TypographyVariant.BodySmall]: 'body',
  [TypographyVariant.BodyXLargeBold]: 'body',
  [TypographyVariant.BodyLargeBold]: 'body',
  [TypographyVariant.BodyRegularBold]: 'body',
  [TypographyVariant.BodySmallBold]: 'body',
  [TypographyVariant.BodyXLargeMedium]: 'body',
  [TypographyVariant.BodyLargeMedium]: 'body',
  [TypographyVariant.BodyRegularMedium]: 'body',
  [TypographyVariant.BodySmallMedium]: 'body',
  [TypographyVariant.BodyRegularExtraLineHeight]: 'body',
  [TypographyVariant.BodySmallExtraLineHeight]: 'body',
  [TypographyVariant.BodyRegularExtraLineHeightBold]: 'body',
  [TypographyVariant.BodySmallExtraLineHeightBold]: 'body',
  [TypographyVariant.BodyRegularExtraLineHeightMedium]: 'body',
  [TypographyVariant.BodySmallExtraLineHeightMedium]: 'body',
}

const weightMap = {
  [TypographyVariant.Heading1]: 'bold',
  [TypographyVariant.Heading2]: 'bold',
  [TypographyVariant.Heading3]: 'bold',
  [TypographyVariant.Heading4]: 'bold',
  [TypographyVariant.Heading5]: 'bold',
  [TypographyVariant.Heading6]: 'bold',
  [TypographyVariant.BodyXLarge]: 'regular',
  [TypographyVariant.BodyLarge]: 'regular',
  [TypographyVariant.BodyRegular]: 'regular',
  [TypographyVariant.BodySmall]: 'regular',
  [TypographyVariant.BodyRegularExtraLineHeight]: 'regular',
  [TypographyVariant.BodySmallExtraLineHeight]: 'regular',
  [TypographyVariant.BodyXLargeBold]: 'bold',
  [TypographyVariant.BodyLargeBold]: 'bold',
  [TypographyVariant.BodyRegularBold]: 'bold',
  [TypographyVariant.BodySmallBold]: 'bold',
  [TypographyVariant.BodyRegularExtraLineHeightBold]: 'bold',
  [TypographyVariant.BodySmallExtraLineHeightBold]: 'bold',
  [TypographyVariant.BodyXLargeMedium]: 'Medium',
  [TypographyVariant.BodyLargeMedium]: 'Medium',
  [TypographyVariant.BodyRegularMedium]: 'Medium',
  [TypographyVariant.BodySmallMedium]: 'Medium',
  [TypographyVariant.BodyRegularExtraLineHeightMedium]: 'Medium',
  [TypographyVariant.BodySmallExtraLineHeightMedium]: 'Medium',
}

function Typography({
  children,
  variant,
  tag,
  className,
  style,
}: Readonly<TypographyProps>): React.JSX.Element {
  const classes = classNames(styles[variant], className)

  return (
    <BaseTypography
      className={classes}
      variant={variantMap[variant]}
      weight={weightMap[variant]}
      tag={tag}
      style={style}>
      {children}
    </BaseTypography>
  )
}

export default Typography

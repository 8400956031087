import { ApiRequestOptions } from '../types/ApiRequestOptions'
import { ApiResult } from '../types/ApiResult'

export class ApiError extends Error {
  public readonly url: string

  public readonly status: number

  public readonly statusText: string

  public readonly body: any

  public readonly request: ApiRequestOptions

  constructor(request: ApiRequestOptions, result: ApiResult, message: string) {
    super(message)

    this.name = 'ApiError'
    this.status = result.status
    this.body = result.body
    this.request = request
  }
}

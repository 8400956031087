import { Session } from 'next-auth'
import {
  Result,
  OrderSummary,
  CreateOrderRequest,
  ReturnRequest,
  OrderListResponse,
  CheckoutDetails,
  GetOrderParams,
  CreateOrderWithLatitudePayRequest,
} from 'shared-types'

import { ApiError } from '../helpers/apiError'
import { IOrderClient } from '../types/Client'
import { request } from './request'

export class OrderClient implements IOrderClient {
  createOrder = (
    payload: CreateOrderRequest,
    session: Session
  ): Promise<Result<OrderSummary, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: '/bff/checkout/order',
        body: payload,
      },
      session
    )
  }

  createOrderWithLatitudePay = (
    payload: CreateOrderWithLatitudePayRequest,
    session: Session
  ): Promise<Result<OrderSummary, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: '/bff/checkout/latitude/order',
        body: payload,
      },
      session
    )
  }

  getOrder = (
    payload: GetOrderParams,
    session: Session
  ): Promise<Result<OrderSummary, ApiError>> => {
    return request(
      {
        method: 'GET',
        url: `/bff/checkout/order/${payload.reference}${
          payload.redirectResult
            ? `?redirectResult=${payload?.redirectResult}`
            : ''
        }`,
      },
      session
    )
  }

  returnOrder = (
    payload: ReturnRequest,
    session: Session
  ): Promise<Result<OrderSummary, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: `/bff/account/return/${payload.orderNumber}`,
        body: { items: payload.products },
      },
      session
    )
  }

  getCustomerOrdersList = async (
    params,
    session: Session
  ): Promise<Result<OrderListResponse, ApiError>> => {
    return request(
      {
        url: `/bff/account/orders?${params}`,
        method: 'GET',
      },
      session
    )
  }

  reOrder = (
    orderNumber: string,
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request(
      {
        method: 'POST',
        url: '/bff/checkout/account/order/reorder',
        body: { orderNumber },
      },
      session
    )
  }
}

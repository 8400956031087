import { useEffect, useState } from 'react'

let seenInteraction = false

export const useUserInteraction = () => {
  const [userHasInteracted, setUserHasInteracted] = useState(seenInteraction)

  useEffect(() => {
    const events = [
      'click',
      'mousemove',
      'touchstart',
      'scroll',
      'keydown',
      'touchmove',
      'wheel',
      'dragstart',
      'focus',
      'blur',
    ]
    const interactionHandler = () => {
      seenInteraction = true
      setUserHasInteracted(seenInteraction)
    }

    events.forEach((event) => {
      window.addEventListener(event, interactionHandler, { once: true })
    })

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, interactionHandler)
      })
    }
  }, [])

  return {
    userHasInteracted,
  }
}

type GTagEvent = {
  action: string
  payload: {
    send_to?: string
    ecomm_prodid?: string | string[]
    ecomm_pagetype: string
    ecomm_totalvalue?: string
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const pageview = ({ action, payload }: GTagEvent): void => {
  typeof window !== 'undefined' &&
    typeof window.gtag === 'function' &&
    window.gtag('event', action, {
      ...payload,
    })
}

export enum State {
  INITIAL,
  RESOLVED,
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Resolver = (payload) => unknown
const thirtySix = 36
export class QueueElement {
  readonly #resolver: Resolver

  readonly #priority: number

  readonly #id = Math.random().toString(thirtySix)

  #state: State

  #data: unknown

  constructor(resolver: Resolver, priority: number) {
    this.#state = State.INITIAL
    this.#priority = priority
    this.#resolver = resolver
  }

  resolve = async (payload?) => {
    this.#data = this.#resolver(payload)
    this.#state = State.RESOLVED
  }

  getData = () => {
    return this.#data
  }

  getId = () => {
    return this.#id
  }

  getState = () => {
    return this.#state
  }

  getPriority = () => {
    return this.#priority
  }
}

import { LineItem } from 'shared-types'
import { ProductDTO } from '~/lib/gtm/declarations'
import { formatPriceForDataLayer } from '~/lib/gtm/helpers'

export const adaptProduct = ({
  product,
  isUseTotalQuantityPriceFormat = false,
}: {
  product: LineItem & { totalQuantity?: number }
  isUseTotalQuantityPriceFormat?: boolean
}): ProductDTO => {
  const productPrice = isUseTotalQuantityPriceFormat
    ? product.totalQuantity
    : product.quantity

  return {
    item_id: product?.variant?.sku,
    item_name: product?.name,
    price: product?.price?.value?.centAmount
      ? Number(
          formatPriceForDataLayer(product.price.value.centAmount / productPrice)
        )
      : 0,
    item_brand: product?.brandName || '',
    item_category: product?.category1Name || '',
    item_category2: product?.category2Name || '',
    item_category3: product?.category3Name || '',
    item_category4: product?.category4Name || '',
    item_category5: product?.category5Name || '',
    quantity: product?.quantity,
    in_stock: product?.inStock ? 'Yes' : 'No',
    item_status_tags: product?.item_status_tags || '',
  }
}

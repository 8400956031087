import { GTMEvent } from '~/lib/gtm/GTM'

export const adaptSelectCheckoutOption = (option) => {
  return {
    event: GTMEvent.SELECT_CHECKOUT_OPTION,
    ecommerce: {
      checkout_option: {
        option,
      },
    },
  }
}

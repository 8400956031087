import { ImageProps } from '@overdose/components'
import classNames from 'classnames'
import { ImageProps as NextImageProps } from 'next/dist/client/legacy/image'
import { Image } from '~/components/Image'
import styles from './CustomImage.module.css'

export type CustomImageProps = ImageProps & NextImageProps

/**
 *  A custom image component passed to AppProvider from @overdose/components.
 *  This component should not be included directly in your component. Instead,
 *  import Image from @overdose/components.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomImage = (props: CustomImageProps) => {
  return (
    <Image
      key={props.src}
      {...props}
      className={classNames(props.className, styles.image, {
        [styles.priority]: props.priority,
      })}
    />
  )
}

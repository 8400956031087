import { GTM, GTMEvent } from '~/lib'

export const useProductDataLayer = () => {
  const selectItem = (product, index, category) => {
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.SELECT_ITEM, { product, index, category })
  }

  const addToCart = (cart) => {
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.ADD_TO_CART, { cart })
  }

  const selectPromotion = ({
    product,
    index,
    category,
    promotionId,
    promotionName,
    creativeName,
    creativeSlot,
  }) => {
    GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
    GTM.dispatch(GTMEvent.SELECT_PROMOTION, {
      product,
      index,
      category,
      promotionId,
      promotionName,
      creativeName,
      creativeSlot,
    })
  }

  return {
    dispatchSelectItemDataLayerEvent: selectItem,
    dispatchAddToCartDataLayerEvent: addToCart,
    dispatchSelectPromotionDataLayerEvent: selectPromotion,
  }
}
